.auth-part-home .other-methods-button {
  text-align: center;
  margin-top: 10px;
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
  color: #7d7d7d;
  padding: 10px 0;
}

.auth-part-home .other-methods-button:hover {
  color: #fff;
}
