.flex {
  display: flex;
}

.text-shadow {
  text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.1);
}

.page-marker .page-wrap {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.page-marker__header {
  display: flex;
  justify-content: space-between;
  height: 50px;
  align-items: center;
}

.page-marker__header button {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px;
  border: none;
  font-size: 16px;
  font-weight: 700;
  background-color: transparent;
  cursor: pointer;
}

.page-marker__header__create {
  color: #fff;
  background: linear-gradient(244.7deg, #22C4DC 0%, #16BF69 100%);
}

.page-marker__header__back {
  color: #7F7F7F;
}

.page-marker__header__back span {
  margin-left: 8px;
}

.maker-uploader {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background: linear-gradient(136.36deg, #836EFF 13.98%, #5E81FE 45.77%, #00A9A9 79.36%);
  padding: 70px;
}

.maker-uploader h1 {
  font-size: 40px;
  font-weight: 900;
  color: #fff;
  text-align: center;
  margin-bottom: 40px;
}

.maker-uploader__dropzone {
  border: 2px dashed #ffffff33;
  padding: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.maker-uploader__dropzone:hover {
  border-color: #ffffff66;
}

.maker-uploader__dropzone p {
  color: #ffffff80;
  margin-top: 10px;
}

.maker-uploader__dropzone h3 {
  font-size: 30px;
  font-weight: 900;
  color: #fff;
}

.maker-uploader__dropzone img {
  margin-right: 30px;
}

.maker-editor {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  padding: 16px;
}

.maker-editor__video {
  flex-grow: 1;
  overflow: hidden;
}

.maker-editor__loader {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,0.85);
  z-index: 2;
}

.maker-editor__loader > * {
  width: 100%;
  max-width: 500px;
}

.maker-editor__video video {
  width: 100%;
  height: 100%;
}

.maker-editor__controls {
  position: relative;
  background: #2D2D2D;
  border-radius: 3px;
  height: 16px;
  margin: 10px;
  flex-shrink: 0;
}

.maker-editor__controls__handler {
  border-radius: 3px;
  background: #12A4E3;
  width: 100%;
  height: 22px;
  margin-top: -3px;
}

.maker-editor__controls__handler:before, .maker-editor__controls__handler:after {
  content: "";
  position: absolute;
  height: 12px;
  width: 2px;
  background: #fff;
  top: 2px;
  border-radius: 1px;
  box-shadow: 1px 0px 0px 0px #0000001A;
}
.maker-editor__controls__handler:before {
  left: 6px;
}
.maker-editor__controls__handler:after {
  right: 6px;
}

.maker-editor__controls__t1 {
  position: absolute;
  left: 12px;
  top: 0;
}
.maker-editor__controls__t2 {
  position: absolute;
  right: 12px;
  top: 0;
}

@media (min-width: 768px) {
  .page-marker__header {
    padding: 20px 0;
  }
}
