.alert-provider {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;
}

.alert-provider .alert {
  background-color: #1ec2ba;
  font-weight: bold;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  height: 60px;
  margin-top: -60px;
  transition: margin-top 200ms ease-out;
}

.alert-provider .alert.error {
  background-color: #e4403f;
}

.alert-provider .alert.visible {
  margin-top: 0;
}
