.search-results.desktop .search-results-panel {
  background-color: #ffffff;
  overflow-y: auto;
  width: 100%;
  max-height: 320px;
}

.search-results.desktop .search-results-item {
  border-bottom: none;
  font-weight: 600;
  height: 33px;
  padding-left: 15px;
}

.search-results.desktop .search-results-item:hover {
  background-color: #eaeaea;
}
