.fullscreenview {
  z-index: 100;
  overflow: scroll;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .9);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.fullscreenview__media {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.fullscreenview__media-video {
  width: 100%;
  height: 100%;
}

.fullscreenview__nav {
  position: fixed;
  top:0;
  bottom: 0;
  transition: all 200ms;
  cursor: pointer;
  opacity: 0;
  width: 20vw;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index:1;
}

.fullscreenview__nav-left {
  left: 0;
}

.fullscreenview__nav-right {
  right: 0;
}

.fullscreenview__nav:hover {
  opacity: 1;
}

.fullscreenview__nav-icon {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, .6);
  border-radius: 100%;
  color: white;
}
