.media.mobile {
  margin: 0 auto;
}

.media {
  max-width: 100%;
  max-height: 500px;
  overflow: hidden;

  & > div {
    padding-top: 100%;
    position: relative;
    overflow: hidden;
  }



  & .media-container1 {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 500px;
    overflow: hidden;
  }

  & .media-container2 {
    position: relative;
    height: 100%;
    width: 100%;
    line-height: 0;
  }

  & img, & video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  & .full-media, & .preview-media, & .video-gif {
    position: absolute;
    left: 0;
    top: 0;
  }
}

.media-op-image {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}


.preview-media_loaded {
  background-image: url('../../assets/gif-background.gif');
}
