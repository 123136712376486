.embed-modal {
  width: 100%;
  max-width: 500px;
  padding: 20px;
}

.embed-modal__header {
  font-size: 26px;
  font-weight: 900;
  margin-bottom: 20px;
}

.embed-modal__description {
  line-height: 22px;
  font-size: 16px;
  color: #909090;
  margin-bottom: 25px;
}

@media (min-width: 550px) {
  .embed-modal__header {
    font-size: 45px;
  }
}
