.other-gif-title {
  display: flex;
  align-items: center;
  color: white;
  font-weight: 900;
}

.page-media-other-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5px;
}

.page-media-other-controls__soource-tag {
  display: flex;
  align-items: center;
  margin-left: 10px;
  text-transform: capitalize;
}
