.embed-modal-form__input-container {
  display: flex;
}

.embed-modal-form__input-label {
  font-size: 14px;
  font-weight: bold;
  color: #FFFFFF;
  margin: 10px 0;
}

.embed-modal-form__input {
  height: 40px;
  min-width: 0;
  flex: 1;
  padding: 15px;
  background: #FFFFFF;
}

.embed-modal-form__copy_mobile {
  height: 40px !important;
  min-width: 40px !important;
}

.embed-modal-form__copy_desktop {
  display: none;
  height: 50px !important;
}

@media (min-width: 550px) {
  .embed-modal-form__input {
    height: 50px;
  }

  .embed-modal-form__input-label {
    font-size: 18px;
  }

  .embed-modal-form__copy_mobile {
    display: none;
  }

  .embed-modal-form__copy_desktop {
    display: block;
  }
}
