.page-media-popup-blame {
  padding: 30px;
  display: flex;
  flex-direction: column;
  max-width: 375px;
  width: 100%;
  overflow: auto;
  max-height: 100vh;
}

.app.mobile .page-media-popup-blame {
  padding-bottom: 100px;
}

.page-media-popup-blame__h1 {
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  font-size: 40px;
  color: #FFFFFF;
  margin: 0;
  padding: 0;
  margin-bottom: 15px;
}

.app.mobile .page-media-popup-blame__h1 {
  font-size: 26px;
}

.page-media-popup-blame__row {
  margin-bottom: 10px;
}

.page-media-popup-blame__row_2x {
  margin-bottom: 20px;
}

.page-media-popup-blame__input {
  width: 100%;
}

.page-media-popup-blame__textarea {
  height: 180px;
  width: 100%;
  resize: none;
}

.page-media-popup-blame__checkbox-container {
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 14px;
  color: #7F7F7F;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-auto-rows: auto;
  grid-gap: 20px;
  align-items: flex-start;
}

.page-media-popup-blame__checkbox {
  background: #FFFFFF;
  border: 0;
  transform: scale(1.5);
  margin:5px 0 0 5px;
  border-radius: 0;
}


.page-media-popup-blame__input,
.page-media-popup-blame__textarea {
  padding: 15px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  font-size: 16px;
  color: #161616;
}

.page-media-popup-blame__input::placeholder,
.page-media-popup-blame__textarea::placeholder {
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  font-size: 16px;
  color: #B3B3B3;
}
