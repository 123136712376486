.events-panel {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
}

.events-panel__carousel {
  position: relative;
}

.events-panel__title {
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  font-size: 14px;
  text-transform: uppercase;
  color: #7F7F7F;
  margin-bottom: 10px;
}

.events-panel__item {
  padding-right: 10px;
}

.app.desktop .events-panel__item {
  padding-right: 20px;
}

.app.desktop .events-panel__title {
  font-size: 16px;
}

.app.mobile .events-panel__title {
  margin-left: 5px;
}
