.auth-social {
  display: flex;
  justify-content: space-between;
}

.auth-social .icon-facebook {
  position: absolute;
  left: 24px;
  top: 11px;
}

.auth-social > *:last-child {
  margin-left: 10px;
  flex-shrink: 0;
}
