.media-thumb {
  position: relative;
  overflow: hidden;
  z-index: 5;

  & .media-thumb-tags {
    text-shadow: 0 0 7px rgba(0, 0, 0, 0.7);
    background: linear-gradient(transparent, rgba(0,0,0,.7));
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    line-height: 45px;
    height: 40px;
    overflow: hidden;
    padding-left: 10px;
    z-index: 10;
    pointer-events: none;
    animation: 150ms slideUp;

    & a {
      font-size: 14px;
      color: #ffffff;
      text-transform: capitalize;
      pointer-events: all;

      &:before {
        content: '#';
        color: #21c4d9;
      }

      &:hover {
        color: #21c4d9;
      }
    }
  }

  &.static {
    & .media-thumb-tags {
      bottom: 0;
      line-height: 1.1;
      height: auto;
    }
  }

  &.danger {
    &:after {
      content: 'dangerous content!';
      display: block;
      position: absolute;
      background-color: red;
      top: 0;
      left: 0;
      right: 0;
      font-weight: bold;
      padding: 15px 5px;
      text-align: center;
    }
  }
}

.media-thumb__actions {
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  height: 40px;
  background: linear-gradient(rgba(0,0,0,.7), transparent);
  transition: top ease-out 150ms;
  text-align: right;
  pointer-events: none;
  animation: 150ms slideDown;
}

@keyframes slideDown {
  0% { top: -40px }
  100% { top: 0px;}
}

@keyframes slideUp {
  0% { bottom: -40px }
  100% { bottom: 0px;}
}

.media-thumb__actions .button {
  width: 40px !important;
  height: 30px;
  opacity: 0.8;
  pointer-events: all;
}

.media-thumb__actions .button .icon {
  text-shadow: 0 0 7px rgba(0, 0, 0, 0.7);
}


.media-thumb__actions .button:hover {
  opacity: 1;
}
