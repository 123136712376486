.events-panel-item {
  height: 100%;
  position: relative;
  display: block;
  background-image: url('/src/assets/gif-background.gif');
}

.events-panel-item__image-container {
  position: relative;
  width: inherit;
  height: inherit;
  overflow: hidden;
  border-bottom: 5px solid;
}


.events-panel-item__image-container::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(360deg, rgba(0, 0, 0, .8) 0, rgba(0, 0, 0, 0) 65.27%);
  opacity: 0.7;
  transition: all 600ms;
}

.page-event__photostatus-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: all 600ms;
}

.events-panel-item__info {
  padding: 20px 20px 25px 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  text-align: center;
}

.events-panel-item__title {
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  font-size: 26px;
  color: #FFFFFF;
  text-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
}

.events-panel-item__description {
  margin-top: 5px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-size: 14px;
  color: #FFFFFF;
  opacity: 0.7;
  text-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
}

.app.desktop .events-panel-item:hover .page-event__photostatus-image {
  transform: scale(1.1);
}

.app.desktop .events-panel-item:hover .events-panel-item__image-container::after {
  opacity: 1;
}
