.smiles-panel {
  display: flex;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.smiles-panel__item {
  width: 70px;
  height: 70px;
  flex-shrink: 0;
  margin-right: 5px;
  border-radius: 15px;
  overflow: hidden;
  background: #161616;
  transition: background 200ms;
}

.app.desktop .smiles-panel__item:hover {
  background: #252525;
}

.smiles-panel__item:last-child {
  margin-right: 0;
}

@media (min-width: 768px) {
  .smiles-panel__item {
    flex: 1;
    width: auto;
    height: 90px;
    margin-right: 10px;
  }

  .smiles-panel-smile-item__image {
    width: 55px;
    height: 55px;
  }
}
