.page-embed {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.page-embed__header {
  z-index: 1;
  position: absolute;
  top: -100px;
  left: 0;
  width: 100%;
  transition: top 150ms;
}

.page-embed__header_mini {
  display: none;
  z-index: 1;
  position: absolute;
  top: -100px;
  right: 0;
  transition: top 150ms;
}

.page-embed_desktop:hover .page-embed__header,
.page-embed_desktop:hover .page-embed__header_mini {
  top: 0;
}

.page-embed__share {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.page-embed__media {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.page-embed__image {
  object-fit: cover;
  display: block;
  height: 100%;
  position: relative;
  width: 100%;
}

@media (max-width: 249px), (max-height: 249px) {
  .page-embed__header,
  .page-embed__share {
    display: none;
  }

  .page-embed__header_mini {
    display: block;
  }
}


.page-embed-share .modal  {
  min-width: 220px;
}
