.page-embed-share__content {
  width: 100%;
  position: relative;
}

.page-embed-share__back-button {
  position: fixed;
  left: 10px;
  top: 10px;
}

.page-embed-share__socials {
  height: 40px;
  max-width: 220px;
  display: flex;
  overflow: hidden;
}

.page-embed-share__socials .button.size-s50 {
  min-width: 40px;
  height: 40px;
}

.page-embed-share__socials .share-buttons__list-item:nth-child(5n) {
  margin-right: 0 !important;
}

.page-embed-share__socials_extended {
  height: auto !important;
}

.page-embed-share__actions {
  max-width: 220px;
  margin-top: 10px;
  display: grid;
  grid-gap: 5px;
}

.page-embed-share__embed {
  max-width: 500px;
  width: 100vw;
  padding: 20px;
}

.button.page-embed-share__embed-button {
  color: rgba(255, 255, 255, 0.6)
}

.button.page-embed-share__embed-button:hover {
  color: rgba(255, 255, 255, 1)
}

.page-embed-share .overlay {
  background: rgba(0, 0, 0, .5);
}


@media (min-width: 550px) {
  .page-embed-share__socials {
    max-width: 270px;
    height: 50px;
  }

  .page-embed-share__actions {
    max-width: 270px;
  }

  .page-embed-share__actions .button,
  .page-embed-share__socials .button {
    height: 50px !important;
    min-width: 50px !important;
  }
}
