.vertical-menu {
  width: 200px;
  color: #ffffff;
  background-color: #000000;
  padding-bottom: 100px;
}

.vertical-menu__logo {
  padding: 20px 0;
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.vertical-menu__logo-image {
  width: 32px;
  margin-right: 15px;
}

.vertical-menu__links {
  border-top: 1px solid #161616;
  padding: 20px 10px;
  margin-top: 20px;
}

.vertical-menu__links-item {
  display: block;
  line-height: 28px;
  font-size: 14px;
  color: #7F7F7F;
}
