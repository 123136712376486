.page-media-tag {
  border-radius: 40px;
  transition: all 200ms;
}

.app.mobile .page-media-tag {
  background-color: #1f1f1f;
}

.page-media-tag a {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 15px;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 600;
  color: #b3b3b3;
}

.page-media-tag a:before {
  content: '#';
  display: inline;
  color: #22c4dc;
  font-weight: 400;
}

.page-media-tag h3 {
  display: inline;
  padding: 0;
  margin: 0;
}

.app.desktop .page-media-tag:hover,
.app.desktop .page-media-tags .button:hover {
  background-color: #222222;
}

.app.desktop .page-media-tag:hover a,
.app.desktop .page-media-tags .button:hover {
  color: #fff;
}

.app.desktop .page-media-tag:active a {
  color: #b3b3b3;
}
