.upload-gif {
  width: 100%;
  padding: 30px 30px;
  background: linear-gradient(360deg, #16bf69 0%, #21c4d9 100%); /* w3c */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#21c4d9', endColorstr='#16bf69',GradientType=0 );
}

.upload-gif__title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
}

.upload-gif__input {
  width: 100%;
  padding: 20px;
}

.upload-gif__separator {
  text-align: center;
  color: white;
  margin: 10px 0;
  display: table;
  white-space: nowrap;
  width: 100%;
  font-size: 14px;
}

.upload-gif__separator:before,
.upload-gif__separator:after {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  content: '';
  display: table-cell;
  position: relative;
  top: 0.5em;
  width: 45%;
}
.upload-gif__separator:before { right: 10px; }
.upload-gif__separator:after { left: 10px; }
