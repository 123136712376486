.vertical-menu-mobile {
  z-index: 99;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #000000;
  display: flex;
  flex-direction: column;
  animation: menuAnimation 300ms;
  -webkit-overflow-scrolling: touch;
}

@keyframes menuAnimation {
  from { left: -50% }
  to { left: 0 }
}

.vertical-menu-mobile__user-panel-wrap {
  background: #222222;
  display: flex;
  min-height: 50px; /* to prevent panel collapsing bug in safari */
  z-index: 1;
}

.vertical-menu-mobile__user-panel {
  flex: 1;
}

.vertical-menu-mobile__content {
  padding-top: 20px;
  overflow-x: scroll;
}

.vertical-menu-mobile__title {
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  font-size: 14px;
  text-transform: uppercase;
  color: #7F7F7F;
  margin: 0 0 10px 5px;
}

.vertical-menu-mobile__user-links {
  margin-top: 20px;
}

.vertical-menu-mobile__events {
  margin-top: 20px;
}

.vertical-menu-mobile__categories {
  margin-top: 20px;
  padding-bottom: 50px;
}

.vertical-menu-mobile__categories-items {
  display: flex;
  flex-wrap: wrap;
  margin: -3px 0;
}

.vertical-menu-mobile__categories-item {
  width: calc(50% - 3px);
  margin: 3px;
  overflow: hidden;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
}

.vertical-menu-mobile__categories-item:nth-child(odd) {
  margin-left: 0;
}

.vertical-menu-mobile__categories-item:nth-child(even) {
  margin-right: 0;
}

.vertical-menu-mobile__categories-item-bg {
  width: 100%;
  height: 100%;
  position: relative;
}

.vertical-menu-mobile__categories-item-image {
  object-fit: cover;
  width: inherit;
  height: inherit;
}

.vertical-menu-mobile__categories-item-bg:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .3);
}

.vertical-menu-mobile__categories-item-name {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 10px;
  font-weight: 700;
  font-size: 18px;
  color: #fff;
  text-shadow: 0 0 5px rgba(0,0,0,.5);
}

.vertical-menu-mobile__links {
  padding: 20px 10px;
}

.vertical-menu-mobile__links-item {
  display: block;
  line-height: 28px;
  font-size: 14px;
  color: #7F7F7F;
}
