.page-user_desktop .page-user-user-panel {
  padding: 25px;
}

.page-user_desktop .page-user__controls {
  margin: -57px 0 0 175px;
}

.page-user_desktop .page-user-user-panel__right-part {
  margin-left: 35px;
  flex-direction: row;
  justify-content: space-between;
}

.page-user_desktop .page-user-user-panel__username {
  font-size: 40px;
  margin-top: 5px;
}

.page-user_desktop .page-user-user-panel__counters {
  margin-top: 0;
  display: flex;
  align-items: flex-end;
}

.page-user_desktop .page-user-user-panel__favorites {
  padding-right: 20px;
  margin-right: 20px;
}

.page-user_desktop .page-user-user-panel__favorites-value {
  font-size: 40px;
  text-align: right;
}

.page-user_desktop .page-user-user-panel__uploaded-value {
  text-align: right;
  font-size: 40px;
}

.page-user_desktop .page-user-controls__item {
  font-size: 15px;
  padding: 20px 15px 19px 15px;
}

.page-user_desktop .page-user-part-uploaded__header,
.page-user_desktop .page-user-part-added__header,
.page-user_desktop .page-user-part-touches__header {
  margin-left: 0;
}

.page-user_desktop .page-user-part-uploaded__title,
.page-user_desktop .page-user-part-added__title,
.page-user_desktop .page-user-part-touches__title,
.page-user_desktop .page-user-part-added__favorites-count,
.page-user_desktop .page-user-part-touches__count,
.page-user_desktop .page-user-part-uploaded__uploaded-count {
  font-size: 26px;
}

.page-user_desktop .page-user-user-panel__uploaded-intl,
.page-user_desktop .page-user-user-panel__favorites-intl {
  font-size: 16px;
  margin-top: 10px;
  display: inline-block;
}
