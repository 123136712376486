.modal-container {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  position: absolute;
  z-index: 2;
  min-width: 250px;
  max-width: 100%;
  max-height: 100%;
  animation: showup 500ms;
}

@keyframes showup {
  from {
    transform: translateY(-30px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

.modal-close-button {
  position: absolute;
  right: 10px;
  top: 20px;
  z-index: 3;
}
