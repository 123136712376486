.page-home.mobile {
  padding-top: 10px;
}

.page-home.mobile .header {
  font-size: 18px;
  margin-bottom: 5px;
}

.page-home.desktop .header {
  margin-bottom: 20px;
  margin-top: 30px;
}

.page-home .sub-header {
  font-size: 15px;
  color: white;
}

.page-home .popular-media {
  margin-top: 20px;
}

.page-home.desktop .popular-media {
  margin-top: 10px;
}

.page-home .popular-media .header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.page-home .page-header {
  margin: 0;
}

.page-home__smiles {
  margin: 20px 0 15px 0;
  max-width: 1100px;
}

.page-home__events {
  margin: 20px 0 30px 0;
  max-width: 1100px;
  overflow: hidden;
}

.app.desktop {
  margin: 30px 0 50px 0;
}

/* кастыль */
.app.desktop .page-home__smiles,
.app.desktop .page-home__events {
  width: calc(100vw - 200px - 40px - 10px);
}
