.page-user-part-added__header {
  margin: 20px 0 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.page-user-part-added__title {
  font-size: 20px;
  color: white;
  font-weight: 600;
}

.page-user-part-added__favorites-count {
  font-size: 20px;
  color: #808080;
  margin-left: 5px;
  font-weight: 600;
}

.page-user-part-added__content-empty {
  margin-top: 20px;
  color: #808080;
  /* font-weight: 600; */
  font-size: 16px;
}
