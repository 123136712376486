.page-auth .header {
  font-size: 45px;
  font-weight: 900;
  text-align: center;
  margin-bottom: 20px;
}

.page-auth {
  max-width: 315px;
  position: relative;
  flex-grow: 1;
  margin: 0 30px;
}

.page-auth .content {
  width: 100%;
  flex-shrink: 0;
}

.page-auth__animation-container {
  display: flex;
  align-items: center;
}

.page-auth .sub-header {
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 20px;
  position: relative;
}

.page-auth.mobile .sub-header {
  font-size: 22px;
}

.page-auth .auth-error {
  font-size: 15px;
  text-align: center;
  margin-top: 10px;
  color: #e44a4a;
}

.page-auth .user-email {
  color: #737373;
  font-size: 15px;
}

.fade-wait-leave.fade-wait-leave-active {
  animation: slide 500ms;
  opacity: 0;
}

.fade-wait-enter.fade-wait-enter-active {
  animation: slide2 500ms;
}

.fade-wait-reverse-leave.fade-wait-reverse-leave-active {
  animation: slide-reverse 500ms;
  opacity: 0;
}

.fade-wait-reverse-enter.fade-wait-reverse-enter-active {
  animation: slide2-reverse 500ms;
}

@keyframes slide-reverse {
  0% {
    opacity: 1;
    transform: translatex(-100%) scale(1);
  }
  100% {
    transform: translatex(0%) scale(.8);
    opacity: 0;
  }
}

@keyframes slide2-reverse {
  0% {
    opacity: 0;
    transform: translatex(-100%) scale(.8);
  }
  51% { opacity: 1; }
  100% {
    transform: translatex(0%) scale(1);
    opacity: 1;
  }
}

@keyframes slide {
  0% {
    opacity: 1;
    transform: translatex(-100%) scale(1);
  }
  100% {
    transform: translatex(-200%) scale(.8);
    opacity: 0;
  }
}

@keyframes slide2 {
  0% {
    opacity: 0;
    transform: translatex(100%) scale(.8);
  }
  51% { opacity: 1; }
  100% {
    transform: translatex(0) scale(1);
    opacity: 1;
  }
}
