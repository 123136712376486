.page-smile__panel {
  margin-top: 20px;
}

.page-smile__tags-container {
  margin: 10px 0;
}

.page-smile__header {
  margin-top: 20px;
}
