.common-list{
  background: #ffffff;
  padding: 10px 0;
}

.common-list__item {
  padding: 10px 20px;
  z-index: 1;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  align-items: center;
  color: black;
}

.common-list__item:hover {
  background: #EEEEEE;
}

.common-list__item-icon {
  margin-right: 15px;
}
