.menu-user-panel {
  display: flex;
  position: relative;
}

.menu-user-panel > *:first-child {
  margin-right: 1px;
  flex: 1;
}

.menu-user-panel__settings {
  min-width: 0px;
  width: 35px;
}

.menu-user-panel__settings:hover .icon {
  color: white !important;
}
