.app.mobile .page-tos {
  padding: 0 20px;
}

.page-tos h1 {
  margin: 30px 0;
  font-size: 32px;
  font-weight: bold;
}

.page-tos h2 {
  margin: 20px 0;
  font-size: 18px;
  font-weight: bold;
}

.page-tos p {
  margin: 10px 0;
  word-break: break-all;
}

.page-tos a {
  color: #22c4dc;
  text-decoration: underline;
}
