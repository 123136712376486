.search-results.mobile {
  -webkit-overflow-scrolling: touch;
}

.search-results.mobile .search-results-panel {
  background-color: #ffffff;
  overflow-y: auto;
  width: 100%;
  max-height: 157px;
}
