.page-embed-header {
  height: 60px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.62) 0%, rgba(0, 0, 0, 0) 100%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 0 15px;
}

.page-embed-header__logo {
  display: flex;
  align-items: center;
}

.page-embed-header__logo-image {
  width: 28px;
  margin-right: 15px;
}

.page-embed-header__share-button {
  min-width: auto;
}

.page-embed-header__share-button .children {
  color: white;
}
