.vertical-menu-categories li a {
  display: flex;
  align-items: center;
  color: #808080;
  text-transform: uppercase;
  font-size: 15px;
  height: 30px;
  line-height: 30px;
  padding-left: 10px;
  font-weight: bold;
  letter-spacing: 0.4px;
}

.app.desktop .vertical-menu-categories li a:hover {
  color: #fff;
  background-color: #404040;
}

.vertical-menu-categories li.main a {
  color: #fff;
}

.vertical-menu-categories li .icon {
  margin-right: 13px;
}

.vertical-menu-categories li.last-main {
  margin-bottom: 10px;
}
