@import url('https://fonts.googleapis.com/css?family=Roboto:400,700,900&subset=cyrillic');

html, body {
  font-size: 16px;
  font-family: Roboto, sans-serif;
  background-color: #000000;
  color: #ffffff;
}

html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

body.noscroll{
  overflow:hidden;
}

a {
  text-decoration: none;
}

input {
  border: none;
  font-size: 16px;
  height: 50px;
  font-weight: bold;
}

input, textarea {
  border: none;
  border-radius: 0;
  font-family: Roboto, sans-serif;
}

input::placeholder {
  color: #b3b3b3;
}

input[type=checkbox] {
  height: auto;
}

.app {
  min-height: 101vh;
}

.app .vertical-menu-container {
  width: 200px;
  flex-shrink: 0;
}


.app-content__search {
  position: sticky;
  top: 0;
  z-index: 10;
}
