.menu-user-panel.mobile {
  background-color: #181818;
}

.menu-user-panel__user-icon {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -12px;
  background-color: rgb(109, 181, 172);
}

.menu-user-panel.mobile .button.size-s50 {
  font-size: 16px;
}
