.page-media-tooltip-blame {
  display: inline-block;
}

.page-media-tooltip-blame__tooltip {
  position: absolute;
  width: 220px;
  margin-left: -110px;
  z-index: 6;
}

