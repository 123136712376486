.page-media-tags {
  display: flex;
  position: relative;
}

.page-media-tags .tags {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  flex-grow: 1;
  height: 40px;
}

.app.desktop .page-media-tags .tags {
  overflow: hidden;
}

.app.mobile .page-media-tags .tags {
  white-space: nowrap;
}
.page-media-tags .tag {
  display: inline-block;
}

.page-media-tags .stub {
  display: inline-block;
  margin-right: 5px;
  height: 40px;
  width: 80px;
  border-radius: 40px;
  background: #1f1f1f;
}

.page-media-tags .stub:last-child {
  margin-right: 0;
}

.app.mobile .page-media-tags .tag {
  margin-right: 5px;
}

.app.mobile .page-media-tags .tag:last-child {
  margin-right: 0;
}

.page-media-tags > .button {
  flex-shrink: 0;
}

.page-media-tags_show-more .tags {
  overflow: hidden;
}

.page-media-tags_extend .tags {
  overflow: auto;
  height: auto;
}

.page-media-tags__show-more-button {
  width: 40px !important;
  height: 40px !important;
}

.page-media-tags__show-more-button .icon {
  color: #b3b3b3;
}

.page-media-tags__show-more-button:hover {
  background-color: #222222;
}

.page-media-tags__show-more-button:hover .icon {
  color: white;
}
