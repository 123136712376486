.smiles-panel-smile-item {
  width: 100%;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
}

.smiles-panel-smile-item_dimmed .smiles-panel-smile-item__image {
  opacity: 0.4;
}

.smiles-panel-smile-item__image {
  transition: all 200ms;
  width: 45px;
  height: 45px;
}

.smiles-panel-smile-item_active .smiles-panel-smile-item__image,
.app.desktop .smiles-panel-smile-item:hover .smiles-panel-smile-item__image {
  transform: scale(1.12);
  opacity: 1;
}
