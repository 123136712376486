.search-dropdown-input {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 5;
  overflow: hidden;
  position: relative;
  background: white;
}

.search-dropdown-input__menu {
  margin-left: -50px;
  transition: all 100ms;
}

.search-dropdown-input__menu-button .icon {
  background: linear-gradient(to right, rgb(22,191,105) 0%, rgb(34,196,220) 51%, rgb(22,191,105) 100%);
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}

.search-dropdown-input_show-menu-button .search-dropdown-input__menu {
  margin-left: 0;
  border-right: 1px solid #e6e6e6;
}

.search-dropdown-input__input {
  flex-grow: 1;
  height: 55px;
  padding-left: 10px;
  font-size: 18px;
  font-weight: 600;
  width: auto;
  min-width: 1px;
  color: #222;
}

.search-dropdown-input__input-buttons {
  display: flex;
  align-items: center;
}

.search-dropdown-input .button {
  width: 85px;
}

.search-dropdown-input.mobile .search-dropdown-input__input {
  height: 50px;
}

.search-dropdown-input.mobile .button {
  width: 50px;
  min-width: 50px;
  height: 50px;
}

.search-dropdown-input.desktop .search-dropdown-input__input {
  padding-left: 15px;
}

.search-dropdown-input.desktop .search-dropdown-input__menu {
  display: none;
}
