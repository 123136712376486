.search-dropdown {
  position: relative;
}

.search-dropdown__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .8);
  z-index: 1;
}

.search-dropdown__results {
  position: relative;
  z-index: 2;
}

.search-dropdown_desktop .search-dropdown__results {
  /* border-bottom: 1px solid #e6e6e6; */
  position: absolute;
  right: 0;
  left: 0;
}

.search-dropdown .search-dropdown__results {
  border-top: 1px solid #e6e6e6;
}

/* Fuck... just sorry... */
.search-dropdown_mobile.search-dropdown_focus {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}
