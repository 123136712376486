.search-hints {
  max-height: 100px;
  overflow: hidden;
}

.search-hints.desktop {
  margin-top: -10px;
}

.search-hints.mobile {
  background-color: #000;
  white-space: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.search-hints__item {
  display: inline-block;
}

.search-hints__stub {
  display: inline-block;
  border-radius: 30px;
  height: 40px;
  width: 80px;
  background: #161616;
  margin-right: 5px;
}

.search-hints.mobile .search-hints__stub {
  height: 35px;
}

.search-hints.mobile .search-hints__item {
  margin: 0 5px 0 0;
}

.search-hints a {
  border-radius: 30px;
  display: block;
  height: 40px;
  line-height: 40px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  font-size: 15px;
  padding: 0 15px;
  transition: all 300ms;
}

.search-hints.mobile a {
  font-size: 15px;
  height: 35px;
  line-height: 35px;
  padding: 0 15px;
  background: #161616;
}
