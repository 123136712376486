.events-panel__navigation {
  height: inherit;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  width: 76px;
  cursor: pointer;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
}

.events-panel__navigation-prev {
  left: 0;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
}

.events-panel__navigation-next {
  right: 0;
  background: linear-gradient(270deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
}

.events-panel__navigation-icon {
  color: rgba(255, 255, 255, .7);
  font-size: 38px;
  transition: color 200ms;
}

.events-panel__navigation:hover .events-panel__navigation-icon {
  color: rgba(255, 255, 255, 1);
}
