.app.desktop {
  max-width: 1340px;
  margin: 0 auto;
  display: flex;
  padding: 0 20px;
}

.app.desktop .app-content {
  flex-grow: 1;
  margin-left: 10px;
}

.app.desktop .app-content__search {
  margin-bottom: 20px;
}
