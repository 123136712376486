.page-media-popup-review {
  padding: 30px;
  display: flex;
  flex-direction: column;
  max-width: 375px;
  width: 100vw;
  display: block;
}

.page-media-popup-review__h1 {
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  font-size: 40px;
  color: #FFFFFF;
  margin: 0;
  padding: 0;
}

.app.mobile .page-media-popup-review__h1 {
  font-size: 26px;
}

.page-media-popup-review__row {
  margin-bottom: 10px;
}

.page-media-popup-review__row_2x {
  margin-bottom: 20px;
}

.page-media-popup-review__input {
  width: 100%;
}

.page-media-popup-review__textarea {
  width: 100%;
  height: 157px;
  resize: none;
}

.page-media-popup-review__input,
.page-media-popup-review__textarea {
  padding: 15px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  font-size: 16px;
  color: #161616;
}

.page-media-popup-review__input::placeholder,
.page-media-popup-review__textarea::placeholder {
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  font-size: 16px;
  color: #B3B3B3;
}
