.events-panel-stub {
  height: 100%;
  display: flex;
}

.events-panel-stub__item {
  margin-right: 20px;
  background: #161616;
}

.events-panel-stub__item:last-child {
  margin-right: 0;
}

.events-panel-stub__item_mobile {
  margin-right: 10px;
}
