.share-buttons__list {
  display: flex;
  flex-wrap: wrap;
}

.share-buttons__list-item {
  margin: 0 5px 5px 0;
}

.share-buttons__list-item:last-child {
  margin-right: 0;
}



.share-buttons_mobile .share-buttons__list {
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.share-buttons_mobile .share-buttons__list-item {
  margin: 0 5px 0px 0;
}
