.tag-thumb-title.default:before {
  position: absolute;
  content: "";
  display: block;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .65);
}

.tag-thumb-title {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  z-index: 10;
}

.tag-thumb-title.default { top: 0; }
.tag-thumb-title.purple {
  height: 40px;
  background-color: #22c4dc;
  position: relative;
}
.tag-thumb-title.purple.mobile { height: 25px; }

.tag-thumb-title .title {
  text-transform: capitalize;
  position: relative;
  font-weight: bold;
  text-align: center;
  font-size: 15px;
  text-shadow: 0 0 5px rgba(0, 0, 0, .5);
  max-width: 80%;
}

.tag-thumb-title.desktop .title {
  font-size: 18px;
}

.tag-thumb-title.desktop:hover .title {
  text-decoration: underline;
}

.tag-thumb-title .title:before {
  content: '#';
  font-weight: 400;
      display:inline-block;

}

.tag-thumb-title.default .title:before {
  color: #22c4dc;
}
