.page-event__photostatus {
  width: 100%;
  height: 275px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  background-image: url('/src/assets/gif-background.gif');
}

.page-event__photostatus-image {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.page-event__photostatus-image-container::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .4);
}

.page-event__photostatus-info {
  z-index: 1;
  text-align: center;
  padding: 20px;
}

.page-event__photostatus-title {
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  font-size: 40px;
  color: #FFFFFF;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
}

@media (min-width: 1366px) {
  .page-event__photostatus-title {
    font-size: 60px;
  }
}

.page-event__photostatus-description {
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  font-size: 16px;
  color: #FFFFFF;
  opacity: 0.8;
}

.page-event__photostatus-share_desktop {
  margin-top: 20px;
  display: inline-block;
}

.page-event__photostatus-share_mobile {
  margin: 5px 0 15px 0;
  overflow-x: scroll;
}

.page-event__smart-tags {
  margin: 15px 0 10px 0;
}

.page-event__masonry {
  margin-top: 13px;
}
