.auth-back-button {
  position: absolute;
  left: -45px;
  top: -10px;
  cursor: pointer;
}

.page-auth.mobile .auth-back-button {
  position: fixed;
  top: 20px;
  left: 20px;
  opacity: 0;
  animation-name: showButton;
  animation-duration: 100ms;
  animation-fill-mode: forwards;
  animation-delay: 500ms;
}

@keyframes showButton {
  from { opacity: 0}
  to {opacity: 1;}
}


