@font-face {
    font-family: "icons";
    src: url("./icons.eot?2c14aa0728fdf58f56030d5d23b95e5b?#iefix") format("embedded-opentype"),
url("./icons.woff2?2c14aa0728fdf58f56030d5d23b95e5b") format("woff2"),
url("./icons.woff?2c14aa0728fdf58f56030d5d23b95e5b") format("woff"),
url("./icons.ttf?2c14aa0728fdf58f56030d5d23b95e5b") format("truetype"),
url("./icons.svg?2c14aa0728fdf58f56030d5d23b95e5b#icons") format("svg");
}

span {
    line-height: 1;
}

span[class^="icon-"]:before, span[class*=" icon-"]:before {
    font-family: icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-Component:before {
    content: "\f101";
}
.icon-Error:before {
    content: "\f102";
}
.icon-Wrong:before {
    content: "\f103";
}
.icon-add-text:before {
    content: "\f104";
}
.icon-arrow-left-2:before {
    content: "\f105";
}
.icon-arrow-left:before {
    content: "\f106";
}
.icon-arrow-right-2:before {
    content: "\f107";
}
.icon-arrow-right:before {
    content: "\f108";
}
.icon-close:before {
    content: "\f109";
}
.icon-complete:before {
    content: "\f10a";
}
.icon-copy-url:before {
    content: "\f10b";
}
.icon-copy:before {
    content: "\f10c";
}
.icon-download:before {
    content: "\f10d";
}
.icon-embed:before {
    content: "\f10e";
}
.icon-facebook:before {
    content: "\f10f";
}
.icon-flag:before {
    content: "\f110";
}
.icon-gear:before {
    content: "\f111";
}
.icon-globe:before {
    content: "\f112";
}
.icon-google:before {
    content: "\f113";
}
.icon-history:before {
    content: "\f114";
}
.icon-insta:before {
    content: "\f115";
}
.icon-like:before {
    content: "\f116";
}
.icon-login:before {
    content: "\f117";
}
.icon-logo-text:before {
    content: "\f118";
}
.icon-mail:before {
    content: "\f119";
}
.icon-menu:before {
    content: "\f11a";
}
.icon-mesenger:before {
    content: "\f11b";
}
.icon-odno:before {
    content: "\f11c";
}
.icon-pause:before {
    content: "\f11d";
}
.icon-pinterest:before {
    content: "\f11e";
}
.icon-play:before {
    content: "\f11f";
}
.icon-plus-web:before {
    content: "\f120";
}
.icon-plus:before {
    content: "\f121";
}
.icon-point:before {
    content: "\f122";
}
.icon-reactions:before {
    content: "\f123";
}
.icon-reddit:before {
    content: "\f124";
}
.icon-search:before {
    content: "\f125";
}
.icon-share:before {
    content: "\f126";
}
.icon-sms:before {
    content: "\f127";
}
.icon-telegram:before {
    content: "\f128";
}
.icon-trash:before {
    content: "\f129";
}
.icon-tumblr:before {
    content: "\f12a";
}
.icon-twitter:before {
    content: "\f12b";
}
.icon-user:before {
    content: "\f12c";
}
.icon-viber:before {
    content: "\f12d";
}
.icon-vkontakte:before {
    content: "\f12e";
}
.icon-whatsup:before {
    content: "\f12f";
}
.icon-write-us:before {
    content: "\f130";
}
