.hor-menu.desktop .controls {
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hor-menu__upload {
  position: relative;
}

.hor-menu__upload-button {
  text-transform: uppercase;
  padding: 0 15px;
  min-width: auto;
  transition-duration: 0ms;
  letter-spacing: 0.4px;
}

.hor-menu.desktop .hor-menu__upload-button_active {
  background: #21c4d9 !important;
}

.hor-menu.desktop .hor-menu__upload-tooltip {
  position: absolute;
  width: 320px;
  top: 35px;
  right: 0;
  z-index: 20;
}
