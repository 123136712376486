.page-user-part-touches__header {
  margin: 20px 0 10px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.page-user-part-touches__title {
  font-size: 20px;
  color: white;
  font-weight: 600;
}

.page-user-part-touches__count {
  font-size: 20px;
  color: #808080;
  margin-left: 5px;
  font-weight: 600;
}
