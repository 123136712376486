.search-results-item {
  display: flex;
  align-items: center;
  padding: 0 10px;
  font-size: 14px;
  border-bottom: 1px solid #e6e6e6;
  text-transform: lowercase;
  height: 45px;
  color: #222;
  font-weight: 600;
  font-size: 15px;
  -webkit-tap-highlight-color: transparent;
}

.search-results-item_active {
  background-color: #eaeaea;
}

.search-results-item .icon {
  margin-right: 5px;
  color: #2fa0e7;
}
