.popup-blame-success {
  display: grid;
  max-width: 375px;
  padding: 30px;
}

.popup-blame-success__title {
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  font-size: 26px;
  color: #FFFFFF;
  text-align: center;
}

.popup-blame-success__description {
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 14px;
  text-align: center;
  color: #7F7F7F;
  margin-top: 5px;
}

.popup-blame-success__action {
  margin-top: 35px;
}
