@keyframes page-media-swipe-animation-left {
  from { left: -99%; }
  to { left: 0; }
}

@keyframes page-media-swipe-animation-right {
  from { right: -99%; }
  to { right: 0; }
}

.page-media-swipe {
  overflow: hidden;
  min-height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-media-swipe .container {
  position: relative;
  overflow: hidden;
}

.page-media-swipe.animate-left .container {
  right: auto;
  animation-name: page-media-swipe-animation-left;
  animation-duration: 500ms;
  animation-timing-function: ease-in;
}

.page-media-swipe.animate-right .container {
  left: auto;
  animation-name: page-media-swipe-animation-right;
  animation-duration: 500ms;
  animation-timing-function: ease-in;
}

.page-media-swipe .swipe-left,
.page-media-swipe .swipe-right {
  width: 60px;
  height: 60px;
  position: absolute;
  top: 50%;
  margin-top: -30px;
  background-color: rgba(0, 0, 0, .6);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ease-out 120ms;
  cursor: pointer;
}

.page-media-swipe .swipe-left {
  left: -60px;
}

.page-media-swipe .swipe-right {
  right: -60px;
}

.page-media-swipe .swipe-right:hover,
.page-media-swipe .swipe-left:hover {
  background-color: rgba(0, 0, 0, 1);
}

.page-media-swipe.desktop:hover .swipe-left {
  left: 0;
}

.page-media-swipe.desktop:hover .swipe-right {
  right: 0;
}
