.page-category__header {
  display: flex;
}

.page-category__header-h1 {
  flex: 1;
}

.app.mobile .page-category__header {
  margin-top: 10px;
}
