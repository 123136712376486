.smart-tags__container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  align-items: center;
}

.smart-tags__list {
  flex: 1;
  overflow: hidden;
  position: relative;
}

.smart-tags__list::after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 30px;
  background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%)
}

.smart-tags .page-media-tags {
  margin-top: 0;
}
