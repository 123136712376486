.page-media-controls .action-button {
  font-size: 15px;
  color: #fff;
}

.page-media-controls .action-button .text {
  white-space: nowrap;
  font-weight: bold;
  font-size: 14px;
}

.page-media-controls .action-button .icon {
  color: white;
}

.page-media-controls.mobile .buttons {
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.page-media-controls.mobile .page-media-controls__button {
  margin-right: 5px;
  background-color: #222222;
}

.page-media-controls.mobile .page-media-controls__button:last-child {
  margin-right: 0;
}

.page-media-controls.mobile .action-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
}

/* .page-media-controls.mobile .action-button:last-child { border-right: none; } */

.page-media-controls.desktop .action-button {
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 35px;
  padding: 0 35px;
  position: relative;
}

.page-media-controls.desktop .action-button:hover,
.page-media-controls.desktop .action-button:hover .icon {
  color: #39ece3;
}

.page-media-controls.desktop .action-button .icon {
  position: absolute;
  left: 0px;
  top: 6px;
}

.page-media-controls .action-button.active,
.page-media-controls .action-button.active .icon {
  color: #39ece3;
}

.page-media-controls .action-button.active .icon {
  animation-name: iconAnimation;
  animation-duration: 170ms;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.47, 0, 0.75, 0.72);
}

@keyframes iconAnimation {
  0% { transform: scale(1) }
  70% { transform: scale(1.2) }
  100% { transform: scale(1) }
}
