.page-media-action-animation {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.page-media-action-animation__inner {
  position: relative;
  height: 135px;
  width: 135px;
  animation-name: inner;
  animation-fill-mode: forwards;
}

.page-media-action-animation__cover {
  position: absolute;
  top: 0;
  right:0;
  left:0;
  bottom: 0;
  background: rgba(0, 0, 0, .8);
  border-radius: 20px;
  box-shadow: 0 0 25px rgba(0, 0, 0, .6);
  animation-duration: 300ms;
  animation-name: cover;
  animation-timing-function: cubic-bezier(0.47, 0, 0.75, 0.72);
  animation-fill-mode: forwards;
}

.page-media-action-animation__icon {
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 65px;
  animation-delay: 100ms;
  animation-duration: 300ms;
  animation-name: icon;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.47, 0, 0.75, 0.72);
  opacity: 0;
}

.page-media-action-animation__text {
  position: absolute;
  top: 95px;
  left: 0;
  right: 0;
  text-align: center;
  color: white;
  font-size: 16px;
  animation-duration: 300ms;
  animation-delay: 200ms;
  animation-fill-mode: forwards;
  animation-name: text;
  opacity: 0;
  font-weight: 600;
  animation-timing-function: cubic-bezier(0.47, 0, 0.75, 0.72);
}

@keyframes inner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes cover {
  0% {
    transform: scale(0.8)
  }
  50% {
    transform: scale(1.2)
  }
  85% {
    transform: scale(0.9)
  }
  100% {
    transform: scale(1)
  }
}

@keyframes icon {
  0% {
    opacity: 1;
    transform: scale(0.8)
  }
  50% {
    transform: scale(1.3)
  }
  85% {
    transform: scale(0.9)
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes text {
  0% {
    opacity: 1;
    transform: scale(0.8)
  }
  50% {
    transform: scale(1.1)
  }
  85% {
    transform: scale(0.9)
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
