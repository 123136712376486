.page-tag .header {
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.app.mobile .page-tag .header {
  padding-top: 20px;
}

.page-tag__tag-name {
  font-size: 26px;
  font-weight: 900;
  color: #ffffff;
  text-transform: capitalize;
}

.page-tag__tag-description {
  margin-top: 10px;
}

.app.desktop .page-tag__tag-name {
  font-size: 40px;
  margin-right: 10px;
  display: inline-block;
}

.app.desktop .page-tag__tag-count {
  display: inline-block;
}

.app.desktop .page-tag__tag-count::before {
  content: '- ';
}

.page-tag__banner {
  margin: 15px 0 0 0;
}

.app.desktop .page-tag__banner {
  margin: 0 0 10px 0;
}

.page-tag__tag-count {
  margin-top: 5px;
  color: #808080;
}

.page-tag__smart-tags {
  margin: 0 0 10px 0;
}
