.tooltip {
  position: relative;
  color: black;
  background: #ffffff;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.2);
}

.tooltip::before {
  position: absolute;
  top: -5px;
  left: 50%;
  content: '';
  background: #FFFFFF;
  transform: rotate(45deg);
  width: 13px;
  height: 13px;
  margin-left: -6.5px;
}
