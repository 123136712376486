.page-user-user-panel {
  display: flex;
  padding: 10px;
  background: #1c1c1c;
}

.page-user-user-panel__right-part {
  flex: 1;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.page-user-user-panel__username {
  font-size: 28px;
  color: white;
  font-weight: 600;
}

.page-user-user-panel__counters {
  margin-top: 10px;
}

.page-user-user-panel__favorites {
  display: inline-block;
  border-right: 1px solid #343434;
  padding-right: 10px;
  margin-right: 10px;
}

.page-user-user-panel__favorites-value {
  font-size: 26px;
  color: #03c2ba;
  display: block;
  font-weight: 600;
}

.page-user-user-panel__favorites-intl {
  font-size: 10px;
  color: #787878;
}

.page-user-user-panel__uploaded {
  display: inline-block;
}

.page-user-user-panel__uploaded-value {
  font-size: 26px;
  color: #03c2ba;
  display: block;
  font-weight: 600;
}

.page-user-user-panel__uploaded-intl {
  font-size: 10px;
  color: #787878;
}
