.page-media.desktop .image-block {
  display: flex;
}

.page-media.desktop .image {
  margin-right: 30px;
  position: relative;
}

.page-media__image-animation {
  z-index: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.page-media__tags {
  margin-top: 15px;
}

.page-media__banner {
  margin: 30px 0;
}

.page-media.desktop .image-right-block {
  max-width: 277px;
}

.page-media.desktop .page-media__social {
  margin-top: 15px;
}

.page-media.desktop .other-media {
  margin-top: 40px;
}

.page-media.desktop h1, .page-media.desktop .other-gif-title {
  font-size: 26px;
  margin: 10px 0 20px 0;
}

.page-media .desc, .page-media.desktop .image-size {
  margin-top: 10px;
}

.page-media__blame {
  margin-left: -10px;
  margin-top: 5px;
}

.page-media__blame-button {
  justify-content: flex-start;
  min-width: auto;
}

.page-media__blame-button:hover,
.page-media__blame-button_active {
  color: white !important;
}
