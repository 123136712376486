.social-block {
  display: flex;
  flex-wrap: wrap;
}

.social-block .button {
  margin-right: 5px;
  flex-shrink: 0;
  height: 50px;
  min-width: 50px;
  width: 50px;
}

.social-block.desktop .button {
  margin-bottom: 5px;
}

.social-block.mobile {
  flex-wrap: nowrap;
}
