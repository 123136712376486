.page-user-part-uploaded__header {
  margin: 20px 0 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.page-user-part-uploaded__title {
  font-size: 20px;
  color: white;
  font-weight: 600;
}

.page-user-part-uploaded__uploaded-count {
  font-size: 20px;
  color: #808080;
  font-weight: 600;
  margin-left: 5px;
}
