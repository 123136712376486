.page-media.mobile h1, .page-media.mobile .other-gif-title {
  font-size: 20px;
  font-weight: bold;
  /* margin-left: 10px; */
}

.page-media.mobile .other-gif-title .icon-arrow-left-2 {
  margin-left: 10px;
}

.page-media.mobile .dimensions {
  margin-top: 10px;
}

.page-media__swipe {
  position: relative;
}

.page-media__scrollable-content {
  margin-top: 5px;
  display: flex;
  align-items: center;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.page-media__scrollable-content_disable {
  overflow: hidden;
}

.page-media.mobile .page-media__controls {
  margin-right: 10px;
}

.page-media__tags {
  margin-top: 15px;
}
