.menu-user-popup {
  position: absolute;
  padding: 10px 0 10px 0;
  background-color: #fff;
  top: 50px;
  left: 50px;
  z-index: 11;
  box-shadow: 0px 0px 15px 2px rgba(34,34,34,0.15);
}

.menu-user-popup.mobile {
  top: 50px;
  right: 0px;
}

.menu-user-popup:before {
  content: '';
  position: absolute;
  top: -8px;
  left: 50%;
  margin-left: -6px;
  width: 0;
  height: 0;
  border-bottom: 8px solid #fff;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
}

.menu-user-popup.mobile:before {
  right: 20px;
  left: auto;
}

.menu-user-popup li > * {
  display: flex;
  align-items: center;
  width: 220px;
  padding: 10px 15px;
  color: #1e1e1e;
  font-size: 15px;
}

.menu-user-popup b {
  font-weight: bold;
}

.menu-user-popup li > *:hover {
  background-color: #e8e8e8;
}

.menu-user-popup li > * .flag-icon { margin-right: 10px; }

.menu-user-popup .icon-arrow-left-2 {
  transform: rotate(-90deg);
}
