.tag-thumb-list {
  padding: 5px 0;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
}

.tag-thumb-list > div {
  padding: 5px;
  flex-grow: 0;
  flex-shrink: 0;
}
