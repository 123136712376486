.auth-part-social .social-button {
  width: 110px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 5px;
  cursor: pointer;
}

.auth-part-social .social-button .title {
  font-size: 15px;
  margin-top: 15px;
}

.auth-part-social .social-button .icon-block {
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.auth-part-social .buttons {
  display: flex;
  flex-wrap: wrap;
}
