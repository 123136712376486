.page-media-user {
  margin-top: 15px;
  color: #808080;
  font-size: 13px;
}

.page-media-user a {
  color: #808080;
  font-weight: bold;
}

.page-media-user a:hover {
  text-decoration: underline;
  color: #fff;
}
