.button {
  position: relative;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 12px;
  border: none;
  transition: all linear 100ms;
  font-weight: bold;
  min-width: 140px;
  font-size: 18px;
  overflow: hidden;
  color: #ffffff;

  &.disabled {
    opacity: .7;
  }

  &.block {
    width: 100%;
  }

  &.without-children {
    min-width: 0;
    padding: 0;
  }

  &.size-small {
    height: 35px;
    font-size: 14px;
    &.without-children { width: 35px; }
  }

  &.size-middle {
    height: 46px;
    &.without-children { width: 46px; }
  }

  &.size-large {
    height: 55px;
    &.without-children { min-width: 55px; }
  }

  &.size-s50 {
     height: 50px;
    &.without-children { min-width: 50px; }
  }

  &.shape-circle {
    border-radius: 30px;
  }

  &.shape-round {
    border-radius: 3px;
  }

  &.type-default {
    background: #222222;
    color: #f5f5f5;

    &:hover { background: #363636; }
  }

  &.type-primary {
    background: #2fa0e7;
    color: #ffffff;
  }

  &.type-disabled {
    background: #848484;
    color: #ffffff;
  }

  &.type-play {
    background: #1ec2ba;
    color: #ffffff;
  }

  &.type-pause {
    background: #c65656;
    color: #ffffff;
  }

  &.type-transparent {
    background: transparent;
    border: 0;
    color: #808080;
  }

  &.type-black-transparent {
    background: rgba(0, 0, 0, .6);
    color: #f5f5f5;

    &:hover { background: rgba(0, 0, 0, .8); }
  }

  &.type-link {
    background: transparent;
    border: 0;
    color: #2fa0e7;
  }

  &.type-gradient {
    background-size: 200% auto;
    background-image: linear-gradient(45deg, rgb(22,191,105) 0%, rgb(34,196,220) 51%, rgb(22,191,105) 100%);
    transition: 0.5s;
  }

  & .button-icon {
    font-size: 0;

    &.left-icon {
      margin-right: 10px;
    }

    &.right-icon {
      margin-left: 10px;
    }

    & .icon {
      font-size: 20px;
    }
  }

  &.animate-icon:hover .button-icon {
     -webkit-animation: loopAnimation 0.4s forwards;
     -moz-animation: loopAnimation 0.4s forwards;
     animation: loopAnimation 0.4s forwards;
  }
}

@-webkit-keyframes loopAnimation {
  49% { -webkit-transform: translatey(100%); }
  50% {
    opacity: 0;
    -webkit-transform: translatey(-100%);
  }
  51% { opacity: 1; }
}
@-moz-keyframes loopAnimation {
  49% { -moz-transform: translatey(100%); }
  50% {
    opacity: 0;
    -moz-transform: translatey(-100%);
  }
  51% { opacity: 1; }
}
@keyframes loopAnimation {
  49% { transform: translatey(100%); }
  50% {
    opacity: 0;
    transform: translatey(-100%);
  }
  51% { opacity: 1; }
}

.type-gradient:hover {
  background-position: right center;
}
