.flag-icon {
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
    width: 1.33333333em;
    line-height: 1em;
    box-shadow: inset 0 0 0 1px rgba(0,0,0,.1);
    display: flex;
}

.flag-icon:before {
    content: "\00a0";
}

.flag-icon-en {
    background-image: url('3x4/en.svg');
}

.flag-icon-ru {
    background-image: url('3x4/ru.svg');
}
