.page-user-controls {
  background: #1c1c1c;
  display: flex;
}

.page-user-controls__item {
  color: #808080;
  padding: 15px 15px 20px 15px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  margin-right: 3px;
}

.page-user-controls__item_active {
  border-bottom: 3px solid white;
  color: white;
}

.app.desktop .page-user-controls__item:hover:not(.page-user-controls__item_active) {
  border-bottom: 3px solid #808080;
}
