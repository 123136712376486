@keyframes linearProgressAnimation {
  0% { background-position: 0 0; }
  100% { background-position: 36px 0; }
}

.linear-progress {
  height: 40px;
  line-height: 40px;
  text-align: center;
  position: relative;
  background-color: #2fa0e7;
  margin: 10px;
  box-sizing: content-box;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
}

.linear-progress .parts {
  margin-left: 5px;
}

.linear-progress .text {
  position: relative;
}

.linear-progress .value {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background-size:36px 39px;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9ItCh0LvQvtC5XzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMzYgMzkiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDM2IDM5OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHN0eWxlIHR5cGU9InRleHQvY3NzIj4uc3Qwe2ZpbGw6IzU1QUJFMDt9LnN0MXtmaWxsOiM2REJDRUE7fTwvc3R5bGU+PHJlY3QgaWQ9IlhNTElEXzRfIiB5PSIwIiBjbGFzcz0ic3QwIiB3aWR0aD0iMzYiIGhlaWdodD0iMzkiLz48ZyBpZD0iWE1MSURfNV8iPjxnIGlkPSJYTUxJRF81MF8iPjxnIGlkPSJYTUxJRF81M18iPjxwb2x5Z29uIGlkPSJYTUxJRF81NF8iIGNsYXNzPSJzdDEiIHBvaW50cz0iMzMsMCAzMCwwIDI3LDAgMjQsMCAyMSwwIDE4LDAgMTgsMyAxNSwzIDE1LDYgMTIsNiAxMiw5IDksOSA5LDEyIDYsMTIgNiwxNSAzLDE1IDMsMTggMCwxOCAwLDIxIDAsMzYgMywzNiAzLDMzIDYsMzMgNiwzMCA5LDMwIDksMjcgMTIsMjcgMTIsMjQgMTUsMjQgMTUsMjEgMTgsMjEgMTgsMTggMjEsMTggMjEsMTUgMjQsMTUgMjQsMTIgMjcsMTIgMjcsOSAzMCw5IDMwLDYgMzMsNiAzMywzIDM2LDMgMzYsMCAiLz48L2c+PGcgaWQ9IlhNTElEXzUxXyI+PHBvbHlnb24gaWQ9IlhNTElEXzUyXyIgY2xhc3M9InN0MSIgcG9pbnRzPSIzMywyNCAzMCwyNCAzMCwyNyAyNywyNyAyNywzMCAyNCwzMCAyNCwzMyAyMSwzMyAyMSwzNiAxOCwzNiAxOCwzOSAyMSwzOSAyNCwzOSAyNywzOSAzMCwzOSAzMywzOSAzNiwzOSAzNiwzNiAzNiwyMSAzMywyMSAiLz48L2c+PC9nPjwvZz48L3N2Zz4=);
  animation: linearProgressAnimation .6s linear infinite;
  transition: width linear 150ms;
}
