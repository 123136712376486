.page-header {
  color: white;
  font-size: 40px;
  font-weight: 900;
  margin: 10px 0;
  display: inline-block;
}

.page-header .icon {
  margin-left: 10px;
}

.app.mobile .page-header {
  font-size: 26px;
}
