.autoplay-button {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  font-size: 14px;
  color: #808080;
}

.autoplay-button > * {
  flex-shrink: 0;
}

.autoplay-button .text {
  margin-right: 10px;
}

.autoplay-button .button {
  min-width: 40px;
  height: 40px;
}
