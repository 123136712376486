.events-panel-carousel-mobile {
  display: flex;
  flex-wrap: nowrap;
  height: inherit;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.events-panel-carousel-mobile .events-panel__item {
  flex-shrink: 0;
}

.events-panel-carousel-mobile .events-panel__item:last-child {
  padding-right: 0;
}

.events-panel-carousel-mobile .events-panel__item-inner {
  height: 100%;
}

.events-panel-carousel-mobile .events-panel-item__title {
  font-size: 22px;
}

.events-panel-carousel-mobile .events-panel-item__description {
  font-size: 13px;
}
